import React, { ReactNode } from "react";
import { i18n } from "next-i18next";
import type { Locale as DateFnsLocale } from "date-fns";
import { sv, de, enGB, fr } from "date-fns/locale";
import { DocumentNode } from "graphql";
import { gql } from "@apollo/client";
import { format, max } from "date-fns";
import { NullableString } from "../types";
import { DateFormats } from "./validation";
import Colors from "../ui-kit/colors";
import { Locale } from "../config/site";
import {
  getCountryCode,
  getCurrencyCode,
  getDefaultLocale,
  isOrg,
} from "./site";
import { inProduction } from "../../config";

export const NON_BREAKING_SPACE = " ";

export const renderIf = (
  conditional: boolean,
  Component: ReactNode
): ReactNode => (conditional ? Component : null);

export const renderEitherComponent = (
  conditional: boolean,
  TruthyComponent: ReactNode,
  FalsyComponent: ReactNode
): ReactNode => (conditional ? TruthyComponent : FalsyComponent);

export function isDefined<T>(argument: T | undefined | null): argument is T {
  return argument !== undefined && argument !== null;
}

export const removeDuplicates = (array: any[] | null | undefined): any[] =>
  Array.from(new Set(array));

export const wait = (seconds: number): Promise<string> =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve("timer klar");
    }, seconds * 1000);
  });

export const uuid = (): string =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const genitiveCase = (name: NullableString, locale?: string): string => {
  if (!locale || !isDefined(name) || name === "") {
    return `${name}`;
  }

  switch (locale as Locale) {
    // https://make.wordpress.org/docs/style-guide/language-grammar/possessives/
    case "en": {
      return `${name}'s`;
    }

    // https://sprakkonsulterna.se/genitiv-med-s-inte-apostrof/
    case "sv": {
      const lastLetter = name?.toLowerCase().substring(name.length - 1);

      return ["s", "x", "z"].includes(lastLetter) ? name : `${name}s`;
    }

    // https://learngerman.dw.com/en/genitive-with-proper-names/l-38182859/gr-38322463
    case "de": {
      const lastLetter = name?.toLowerCase().substring(name.length - 1) || "";

      return ["s", "x", "z", "ß"].includes(lastLetter)
        ? `${name}'`
        : `${name}s`;
    }

    // https://awesomefrench.tumblr.com/post/52227591852/de-vs-d-du-de-l-de-la#:~:text=D'%20is%20the%20contraction%20of,change%20the%20meaning%20at%20all.&text=%E2%80%9CSome%E2%80%9D%20would%20technically%20translate%20into,progressively%20into%20these%20new%20forms.
    case "fr": {
      const firstLetter = name?.toLocaleLowerCase().substring(0, 1) || "";

      return ["a", "e", "i", "o", "u"].includes(firstLetter)
        ? `d'${name}`
        : `de ${name}`;
    }

    default:
      return name;
  }
};

// This does: "2021-02-12T15:56:12.911Z" into "2021-02-12"
export const formatDate = (
  date: string | undefined,
  dateFormat?: DateFormats
): string => {
  if (!date) {
    return "";
  }
  if (dateFormat) {
    return format(new Date(date), dateFormat);
  }
  return date?.split("T")[0];
};

export const formatReadableMonthYear = ({
  date,
  locale,
}: {
  date: string;
  locale?: string;
}) =>
  new Intl.DateTimeFormat(locale, {
    year: "numeric",
    month: "short",
  })
    .format(new Date(date))
    .replace(".", "");

export const formatReadableDayMonth = ({
  date,
  locale,
  includeYear,
}: {
  date: string;
  locale?: string;
  includeYear?: boolean;
}) =>
  new Intl.DateTimeFormat(locale, {
    day: "numeric",
    month: "short",
    year: includeYear ? "numeric" : undefined,
  })
    .format(new Date(date))
    .replace(".", "");

type CurrencyFormatOptions = Pick<
  Intl.NumberFormatOptions,
  "maximumFractionDigits"
>;
// 127000 --> 127 000, etc
export const formatCurrency = (
  number: number | undefined,
  options?: CurrencyFormatOptions
): string => {
  if (typeof number === "undefined") {
    return "";
  }

  // Handle strings since they may be passed in from external data that has been type cast.
  if (typeof number === "string") {
    return number;
  }

  const { maximumFractionDigits = 2 } = options || {};

  return new Intl.NumberFormat(`${getDefaultLocale()}-${getCountryCode()}`, {
    style: "currency",
    currency: getCurrencyCode(),
    maximumFractionDigits,
    minimumFractionDigits: Math.min(maximumFractionDigits, 2),
  })
    .format(number)
    .replace(/[,\.]00/g, "")
    .replaceAll(" ", NON_BREAKING_SPACE);
};

export const formatSignedCurrency = (
  number: number,
  options?: CurrencyFormatOptions
) => {
  const isPositive = number > 0;
  const isNegative = number < 0;

  const formattedNumber = formatCurrency(number, options);

  if (isPositive) {
    return `+${NON_BREAKING_SPACE}${formattedNumber}`;
  }

  if (isNegative) {
    return `−${NON_BREAKING_SPACE}${formattedNumber
      .replace("−", "")
      .replace("-", "")}`;
  }

  return formattedNumber;
};

export const formatNumber = (number: number): string => {
  return new Intl.NumberFormat("sv-SE").format(number);
};

export const joinArray = (arr: [string]): string => {
  const andLower = i18n?.t("format.lowercase", {
    str: i18n?.t("global.and"),
  });
  return arr.join(", ").replace(/,([^,]*)$/, ` ${andLower}$1`);
};

export const parseCurrency = (number: string | undefined): number => {
  if (!number) {
    return 0;
  }
  return parseInt(number, 10);
};

export const intLength = (int: number | undefined): number | undefined =>
  int?.toString().length;

export const isMobileDevice = (): boolean => {
  if (onServer) {
    return true;
  }
  const ua = navigator.userAgent;

  return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
    ua
  );
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const SHA256 = async (text: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
};

export const inBrowser = typeof window === "object";
export const onServer = typeof window === "undefined";

export const toCamelCase = (string: string): string =>
  string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const toPascalCase = (string: string): string =>
  toCamelCase(string).charAt(0).toUpperCase() + toCamelCase(string).slice(1);

export const toFirstLetterUpperCase = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const replaceMarkup = ({
  text,
  replace,
}: {
  text: string;
  replace: {
    match: string;
    tag: keyof JSX.IntrinsicElements;
    getProps: (part: string) => Record<string, any>;
  }[];
}): ReactNode => {
  const current = replace[0];

  if (!current) {
    return text;
  }

  const parts = text.split(`[${current.match}]`);

  return parts.map((part, index) =>
    index % 2 === 0 ? (
      replaceMarkup({ text: part, replace: replace.slice(1) })
    ) : (
      <current.tag key={part} {...current.getProps(part)}>
        {replaceMarkup({ text: part, replace: replace.slice(1) })}
      </current.tag>
    )
  );
};

export const replaceCommonMarkup = (text: string): ReactNode => {
  return replaceMarkup({
    text,
    replace: [
      {
        match: "b",
        tag: "span",
        getProps: () => ({ style: { fontWeight: 500 } }),
      },
      {
        match: "nowrap",
        tag: "span",
        getProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
      {
        match: "email",
        tag: "a",
        getProps: (part) => ({
          href: `mailto:${part}`,
          style: { color: Colors.blue300 },
        }),
      },
    ],
  });
};

export const getAppUrl: () => string = () => {
  const ua = navigator.userAgent.toLowerCase();
  const isAndroid = ua.indexOf("android") > -1;
  if (isAndroid) {
    return "https://play.google.com/store/apps/details?id=com.teamlassie.lassieapp";
  }
  return "https://apps.apple.com/se/app/lassie/id1533808157";
};

export function gqlArray(fragmentArray: DocumentNode[]) {
  const str = new Array(fragmentArray.length + 1).fill("");

  // @ts-ignore
  str.raw = str;
  return gql(str, ...fragmentArray);
}

export const contentfulLocale = (locale: string) => {
  if (!locale) {
    return "";
  }
  if (locale === "en") {
    return "en-GB";
  }
  return locale.replace("_", "-");
};
export const websiteLocale = (locale: string) => locale.replace("-", "_");

const localeToDateFnsLocaleMap: Record<Locale, DateFnsLocale> = {
  sv: sv,
  de: de,
  en: enGB,
  fr: fr,
};

export const getDateFnsLocaleFromLocale = (locale: Locale) =>
  localeToDateFnsLocaleMap[locale];

export function isLocalStorageAvailable() {
  const test = "test";
  try {
    window.localStorage.setItem(test, test);
    window.localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export function isSessionStorageAvailable() {
  const test = "test";
  try {
    window.sessionStorage.setItem(test, test);
    window.sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
